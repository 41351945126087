import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

const EditorialPolicyPage = ({ pageContext }) => {
  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>Editorial Policy</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              <span style={{ color: "#374151" }}>Editorial Mission</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                At Travel Lingual, our editorial mission is to inspire, inform,
                and empower travellers worldwide. We are committed to delivering
                high-quality, accurate, and engaging content that serves the
                needs and interests of our audience.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>Editorial Independence</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Our editorial team operates independently from advertisers and
                sponsors. We maintain editorial control over the content we
                produce, and our recommendations are based on the merit of the
                information, products, or services being reviewed.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>Content Guidelines</span>
            </p>
            <ul>
              <li>
                <span style={{ color: "#374151" }}>
                  Accuracy: We strive for factual accuracy and provide reliable
                  information. In the event of errors or inaccuracies, we are
                  committed to promptly correcting them.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Transparency: We clearly disclose any potential conflicts of
                  interest, sponsorships, or partnerships related to our
                  content.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Diversity and Inclusivity: We aim to represent diverse
                  perspectives and experiences in our content, ensuring
                  inclusivity and respect for all cultures and backgrounds.
                </span>
              </li>
            </ul>
            <p>
              <span style={{ color: "#374151" }}>User-Generated Content</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Travel Lingual welcomes user-generated content, such as
                comments, reviews, and submissions. However, we reserve the
                right to moderate and remove content that violates our community
                guidelines or contains offensive, inappropriate, or harmful
                material.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>Corrections and Feedback</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                We value feedback from our users. If you believe there is an
                error or issue with our content, please contact us, and we will
                investigate and, if necessary, make appropriate corrections.
              </span>
            </p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default EditorialPolicyPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Editorial Policy | Travel-Lingual"}
      description={
        "Accessibility at Travel Lingual. At Travel Lingual, we are committed to providing a welcoming and accessible online experience for all visitors, regardless of their abilities."
      }
      pathname={location.pathname}
    />
  );
};
